import firebase from "firebase/app";

import "firebase/auth";
import "firebase/database";
import "firebase/storage";

var firebaseConfig = {
  apiKey: "AIzaSyAIZo_TAvTWPR_bJ2Ub0Ib2j-pwRUbbvM0",
  authDomain: "react-slack-clone-c6866.firebaseapp.com",
  databaseURL: "https://react-slack-clone-c6866.firebaseio.com",
  projectId: "react-slack-clone-c6866",
  storageBucket: "react-slack-clone-c6866.appspot.com",
  messagingSenderId: "111004857860",
  appId: "1:111004857860:web:44be3b96f94ebbbec02751",
  measurementId: "G-TDEJB2E24J"
};

firebase.initializeApp(firebaseConfig);

export default firebase;
